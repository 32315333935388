import React, { useContext } from "react";

import { CurrentUserContext } from "../providers/auth";

import * as Styles from "./sign-in-with-google.module.css";

const SignInWithGoogle = () => {
    const currentUser = useContext(CurrentUserContext);

    // Gatsby build warns that a button should have a text label so <span/> is added
    return (
        <button
            className={Styles.googleSigninButton}
            onClick={() => {
                currentUser.signInWithGoogle();
            }}
        >
            <span className="hidden">""</span>
        </button>
    );
};

export default SignInWithGoogle;
