import React, { useEffect, useState, useRef } from "react";
import { getMarketingSiteContent } from "../js/cpasApi";

const TermsAndCondition = () => {
    const CPAS_EARTH_JSONAPI_ENDPOINT =
        "https://cpas.earth/jsonapi/node/article";
    const TERMS_UUID = "81314dfc-9136-4659-b2b1-c20f4d310ac9";

    const [terms, setTerms] = useState("");

    useEffect(() => {
        getMarketingSiteContent({
            cpasMarketingSiteEndpoint: CPAS_EARTH_JSONAPI_ENDPOINT,
            uuid: TERMS_UUID,
        })
            .then((d) => setTerms(d.data.data.attributes.body.processed))
            .catch((err) => {
                console.error(err);
                setTerms(
                    `<div>Please refer to our Terms and Conditions at <a href="https://cpas.earth/terms"><u>https://cpas.earth/terms</u></a> .</div>`
                );
            });
    }, []);

    return (
        <div>
            <h1 className="text-4xl font-bold">Terms and Conditions</h1>
            <br></br>
            <div
                className="terms-condition"
                dangerouslySetInnerHTML={{ __html: terms }}
            ></div>
        </div>
    );
};

const Privacy = () => {
    const getPrivacy = (d, s, id) => {
        var js,
            tjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://app.termly.io/embed-policy.min.js";
        tjs.parentNode.insertBefore(js, tjs);
    };

    useEffect(() => {
        getPrivacy(document, "script", "termly-jssdk");
    }, []);

    return (
        <div
            data-id="3bb49f9e-af01-4b18-890d-c755e0424fba"
            data-type="iframe"
            name="termly-embed"
        ></div>
    );
};

const AcceptTnCBox = ({ acceptAction }) => {
    const [scrolledToBottom, setScrolledToBottom] = useState(false);
    const scrollableRef = useRef();

    return (
        <div className="flex flex-col rounded w-full md:w-2/3 lg:w-2/3 h-3/4 bg-lightgreen1 px-4 py-6 z-10">
            <div
                className="overflow-auto"
                onScroll={() => {
                    if (scrollableRef.current) {
                        const {
                            scrollTop,
                            scrollHeight,
                            clientHeight,
                        } = scrollableRef.current;
                        // add 10px allowance instead require exact match to detect scrolled to bottom
                        if (scrollTop + clientHeight + 10 > scrollHeight) {
                            setScrolledToBottom(true);
                        }
                    }
                }}
                ref={scrollableRef}
            >
                <div className="bg-white">
                    <div className="pt-6 px-8">
                        <TermsAndCondition />
                    </div>
                    <hr />
                    <Privacy />
                </div>
            </div>
            <button
                className="primary btn my-4"
                onClick={acceptAction || (() => {})}
                disabled={!scrolledToBottom}
            >
                I Agree to Terms and Conditions and Privacy Policy
            </button>
        </div>
    );
};

export default AcceptTnCBox;
