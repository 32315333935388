import React, { useContext, useState } from "react";
import { Link } from "gatsby";

import {
    CurrentUserContext,
    ATTR_RECEIVE_NEWS,
    ATTR_ACCEPTED_TERMS_TIME,
} from "../providers/auth";

import Layout from "../components/layout";
import Seo from "../components/seo";
import SignInWithGoogle from "../components/sign-in-with-google";
import AcceptTnCBox from "../components/acceptTnCBox";

export default function SignUpPage() {
    return (
        <Layout>
            <Seo title="Sign Up" />
            <div className="flex flex-col md:justify-center items-center p-4 w-full h-screen -mt-16 pt-16">
                <div className="absolute inset-0 mesh-bg -z-10" />
                <SignUpBox />
            </div>
        </Layout>
    );
}

// local components

const SignUpBox = () => {
    const currentUser = useContext(CurrentUserContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [interacted, setInteracted] = useState(false);
    const [receiveNews, setReceiveNews] = useState(true);
    const [agreedTermsAndPrivacy, setAgreedTermsAndPrivacy] = useState(false);

    const setSignUpSuccess = (email) => {
        setSuccess(true);
        window.localStorage.setItem("firstSignUpEmail", email);
    };

    if (!agreedTermsAndPrivacy) {
        return (
            <AcceptTnCBox acceptAction={() => setAgreedTermsAndPrivacy(true)} />
        );
    } else {
        return (
            <div className="overflow-auto flex flex-col rounded w-full md:w-2/3 lg:w-2/5 bg-lightgreen1 px-4 py-6">
                <h1 className="text-lg mb-2">Sign up</h1>
                {success ? (
                    <div className="flex flex-row items-center">
                        <span>
                            Verification email has been sent to {email}. Go to
                            <Link to="/signin" className="mx-1 underline">
                                Sign in
                            </Link>
                            after verified your email address.{" "}
                        </span>
                    </div>
                ) : (
                    <>
                        <div className="flex flex-row justify-center">
                            <SignInWithGoogle />
                        </div>
                        <div className="flex flex-row my-2">
                            <hr className="flex-grow border-b border-grey-light" />
                            <span className="mx-2 text-sm">or</span>
                            <hr className="flex-grow border-b border-grey-light" />
                        </div>
                        <input
                            className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {interacted && !email ? (
                            <div class="flex flex-row justify-center text-red text-xs">
                                Email is required.
                            </div>
                        ) : null}
                        {interacted && email.indexOf(" ") >= 0 ? (
                            <div class="flex flex-row justify-center text-red text-xs">
                                Email shall not contain space.
                            </div>
                        ) : null}
                        <input
                            className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="flex flex-row text-gray-400 text-xs px-4">
                            Password shall be minimum 8 characters including
                            uppercase letter, lowercase letter and number
                        </div>
                        {interacted && !password ? (
                            <div class="flex flex-row justify-center text-red text-xs">
                                Password is required.
                            </div>
                        ) : null}
                        <input
                            className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                            type="password"
                            placeholder="Re-enter Password"
                            value={passwordAgain}
                            onChange={(e) => setPasswordAgain(e.target.value)}
                        />
                        {interacted && password !== passwordAgain ? (
                            <div class="flex flex-row justify-center text-red text-xs">
                                Password and Re-enter Password are not matched.
                            </div>
                        ) : null}
                        <button
                            className="primary btn my-4"
                            onClick={() => {
                                setInteracted(true);
                                if (
                                    email &&
                                    email.indexOf(" ") < 0 &&
                                    password &&
                                    password === passwordAgain
                                ) {
                                    let attrs = {};
                                    attrs[ATTR_RECEIVE_NEWS] = receiveNews
                                        ? "1"
                                        : "0";
                                    attrs[
                                        ATTR_ACCEPTED_TERMS_TIME
                                    ] = new Date().toISOString();
                                    currentUser
                                        .signUp(email, password, attrs)
                                        .then(() => setSignUpSuccess(email))
                                        .catch((err) => setError(err));
                                }
                            }}
                        >
                            Sign up
                        </button>
                        {error ? (
                            <div className="flex flex-row justify-center items-center px-2 pt-1 pb-4 text-red text-xs">
                                {error.message}
                            </div>
                        ) : null}
                        <div className="flex flex-col items-center">
                            <label className="flex-grow text-sm ml-2 leading-normal">
                                By clicking "Sign up" or "Sign in with Google",
                                you agree to our{" "}
                                <a
                                    href="https://cpas.earth/terms"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="italic text-blue underline"
                                >
                                    Terms and Conditions
                                </a>{" "}
                                and{" "}
                                <a
                                    href="https://cpas.earth/privacy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="italic text-blue underline"
                                >
                                    Privacy Policy
                                </a>
                                .
                            </label>
                            <label className="text-xs ml-2 mt-3 flex flex-row items-start">
                                <input
                                    className="mt-1 mr-2 leading-tight"
                                    type="checkbox"
                                    checked={receiveNews}
                                    onChange={() =>
                                        setReceiveNews(!receiveNews)
                                    }
                                />
                                <div>
                                    I would like to receive news and offers from
                                    ClusterTech via email (e.g. special offers,
                                    product updates, news related to events,
                                    services and solutions etc.)
                                    <div className="mt-1">
                                        Notes: ClusterTech will not disclose
                                        your email address to unauthorised third
                                        parties. You can unsubscribe at any
                                        time.
                                    </div>
                                </div>
                            </label>
                        </div>
                        <hr className="w-full border-b border-grey-light my-4" />
                        <div className="flex flex-row justify-center items-center text-xs">
                            <span className="px-2">
                                Already have an account?
                            </span>
                            <Link
                                to="/signin"
                                className="text-black font-bold underline"
                            >
                                Sign in
                            </Link>
                        </div>
                    </>
                )}
            </div>
        );
    }
};
